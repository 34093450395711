<template>
  <div class="PageWrapper">
    <div class="PageContent">
      <div class="PageTitle">
        <span class="PTitle">Профориентация</span>
        <span v-if="!isEnd" class="PTButton noselect"
          >Прекратить тестирование</span
        >
      </div>

      <div class="ChatWrapper">
        <TransitionGroup
          name="chat"
          class="Chat"
          tag="div"
          @before-enter="beforeEnter"
          @enter="enter"
          @after-enter="afterEnter"
          @leave="onLeave"
        >
          <div
            v-for="(mes, mindx) in Chat"
            :key="`mes-${mindx}`"
            :class="{ ChatBubble: true, userBorder: mes.itsUserMessage }"
          >
            <span class="CBText">{{ mes.text }}</span>
            <span class="CBTimeTip"
              >{{ mes.itsUserMessage ? "Вы" : mes.sender }},
              {{ mes.time }}</span
            >

            <template v-if="isEnd && mindx === Chat.length - 1">
              <div class="CBButtons">
                <router-link :to="`/beta/cg/result-page${resultQuery}`">
                  <ButtonGradientInsideElement ButtonText="К результату" />
                </router-link>

                <!-- <span class="SecondaryButton noselect">Пройти повторно</span> -->
              </div>
            </template>
          </div>

          <div v-if="waitingGPTMessage" class="ChatBubble CBWait">
            <div class="dot-container">
              <div class="dot"></div>
              <div class="dot"></div>
              <div class="dot"></div>
            </div>
          </div>
        </TransitionGroup>

        <div class="ChatInput">
          <div class="InputWrapper">
            <input
              v-model="chatInput"
              :disabled="blockUserInput || isEnd"
              type="text"
              placeholder="Начните вводить текст"
              :style="`opacity: ${blockUserInput ? '0.2' : '1'};`"
            />
            <span
              class="inpButton noselect"
              :style="
                `cursor: ${
                  blockUserInput || isEnd ? 'default' : 'pointer'
                }; opacity: ${blockUserInput || isEnd ? '0.5' : '1'};`
              "
              @click="sendUserInput"
              >Отправить</span
            >
          </div>
        </div>

        <span class="chatTip noselect"
          >данный профориентационный тест создан при помощи искусственного
          интелекта</span
        >
      </div>
    </div>
  </div>
</template>

<script>
import gsap from "gsap";

import { sendMessage } from "@/api/career_guidance.js";
import { getSectorsWithSections } from "@/api/competence.js";

import ButtonGradientInsideElement from "@/components/CustomElements/ButtonGradient";

export default {
  components: {
    ButtonGradientInsideElement,
  },
  data() {
    return {
      FIRST_GPT_QUESTION: `Давай начнем с того, что ты немного расскажешь о себе. Какой у тебя опыт работы? Какие предметы в ВУЗе тебе нравятся больше всего? Чем любишь заниматься в свободное время?`,
      Chat: [],

      ChatGPTCache: {
        lastUserMessage: "",
        lastGPTMessage: "",
      },

      blockUserInput: true,
      chatInput: "",
      waitingGPTMessage: true,

      isEnd: false,
      error: false,

      sectorsList: [],

      resultQuery: "",
    };
  },
  async created() {
    getSectorsWithSections().then((response) => {
      this.sectorsList = response.data;
    });
  },
  async mounted() {
    this.showNewGPTMessage(this.FIRST_GPT_QUESTION);

    //Логика скрытия плавающей кнопки "Задать вопрос"
    this.resizeChecker();

    let self = this;
    addEventListener("resize", this.resizeChecker);
    addEventListener("keydown", function(event) {
      if (event.key === "Enter") {
        self.sendUserInput();
      }
    });
  },
  methods: {
    async sendUserInput() {
      if (this.blockUserInput || this.isEnd || this.chatInput.length < 2) {
        return 0;
      } else {
        this.blockUserInput = true;
      }

      let messageData = {
        text: this.chatInput,
        time: this.getTimeNow(),
        sender: "Вы", //"Октагон"
        itsUserMessage: true,
      };

      this.ChatGPTCache.lastUserMessage = this.chatInput;
      this.Chat.push(messageData);

      this.waitingGPTMessage = true;

      let backendData = {
        lastData: this.ChatGPTCache,
        messages: this.Chat.map((x) => {
          let messageRole = x.itsUserMessage ? "user" : "assistant";

          return { role: messageRole, content: x.text };
        }),
      };

      let GPTResponse = null;
      try {
        GPTResponse = await sendMessage(backendData);
        this.chatInput = "";
      } catch (e) {
        alert("При отправке сообщения возникла неполадка. Попробуйте снова");
        this.blockUserInput = false;
        return 0;
      }

      console.log(GPTResponse.data);

      let _preEnd = GPTResponse.data.done;

      if (!_preEnd) {
        this.showNewGPTMessage(GPTResponse.data.message);
        this.blockUserInput = false;
      } else {
        let CompList = GPTResponse.data.compList;
        if (CompList == null || CompList == "отсутсвует" || CompList == "") {
          this.error = true;
          this.showNewGPTMessage(
            "Произошла ошибка при получении ответа. Попробуйте заного. Если ошибка повторяется - обратитесь в поддержку."
          );
        }

        CompList = CompList.split(",");
        CompList = CompList.map((x) => {
          return x
            .split(" ")
            .join("")
            .toLowerCase();
        });

        let result = CompList.map((x) => {
          let find = this.sectorsList.find((sobj) => {
            let _converted = sobj.name
              .split(" ")
              .join("")
              .toLowerCase();

            return _converted === x;
          });

          if (find != null) {
            return find;
          } else {
            return null;
          }
        }).filter((x) => x != null);

        let names = result.map((x) => x.name.replaceAll("&", "$$$"));
        let sids = result
          .map((x) => {
            return x.sections.map((i) => i.id);
          })
          .flat();
        this.resultQuery = `?Names=${names.join(",")}&SectorsIDs=${sids.join(
          ","
        )}`;

        this.showNewGPTMessage(GPTResponse.data.message);
      }

      this.isEnd = GPTResponse.data.done;
    },
    showNewGPTMessage(textFromGPT) {
      let messageData = {
        text: textFromGPT,
        time: this.getTimeNow(),
        sender: "Октагон",
        itsUserMessage: false,
      };

      this.waitingGPTMessage = false;

      this.ChatGPTCache.lastGPTMessage = textFromGPT;
      this.Chat.push(messageData);
      setTimeout(() => {
        this.blockUserInput = false;
      }, 1000);
    },

    getTimeNow() {
      const now = new Date();

      // Получаем часы и минуты
      let hours = now.getHours();
      let minutes = now.getMinutes();

      // Добавляем ведущий ноль, если число меньше 10
      if (hours < 10) {
        hours = "0" + hours;
      }
      if (minutes < 10) {
        minutes = "0" + minutes;
      }

      // Формируем строку времени
      return `${hours}:${minutes}`;
    },
    resizeChecker() {
      let hideAskButton = window.innerWidth < 1400;

      let askButton = document.querySelector(
        'a[href="https://t.me/octagon_help_bot"]'
      );

      if (askButton) {
        askButton.style.display = hideAskButton ? "none" : "block";
      }
    },

    // Хук перед началом входа элемента
    beforeEnter(el) {
      gsap.set(el, { x: -100, opacity: 0 });
    },
    // Хук входа элемента
    enter(el, done) {
      let _delay = el.classList.contains("CBWait") ? 1 : 0.2;

      gsap.to(el, {
        x: 0,
        opacity: 1,
        duration: 0.5,
        delay: _delay,
        ease: "power3.inOut",
        onComplete: () => {
          done();
          this.scrollToEnd(el);
        }, // Важно вызвать done, чтобы Vue знал, что анимация завершена
      });
    },
    // Хук после входа элемента (опционально)
    afterEnter(el) {
      // Можно выполнить дополнительные действия после анимации
    },
    // Хук перед выходом элемента (опционально)
    onLeave(el, done) {
      gsap.to(el, {
        x: 0,
        opacity: 0,
        duration: 0.2,
        delay: 0,
        ease: "power3.inOut",
        onComplete: done, // Важно вызвать done, чтобы Vue знал, что анимация завершена
      });
    },
    // Фукнция скролла до элемента внутри списка
    scrollToEnd(newBlock) {
      // Получаем позицию верхнего края блока относительно верха страницы
      const el = document.querySelector(".Chat");

      // Пролистываем страницу до якорного блока
      el.scrollTo({
        top: newBlock.offsetTop,
        behavior: "smooth", // Добавляем плавную анимацию скролла
      });
    },
  },
};
</script>

<style scoped>
.PageWrapper {
  position: relative;
  display: block;

  padding: 40px 0%;

  width: 100%;
  height: 100svh;

  overflow: hidden;

  background: url("./../../assets/img/ReDesign/pages/career_guidance/bg_light.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  background-color: #19173b;
}
.PageContent {
  position: relative;
  display: flex;

  flex-direction: column;
  justify-content: flex-start;
  gap: 40px;

  margin: 0% auto;

  width: 1128px;
  height: 100%;

  overflow: hidden;
}
.PageTitle {
  position: relative;
  display: flex;

  flex-direction: row;
  justify-content: space-between;
  gap: 10px;

  width: 100%;
  height: fit-content;

  flex-shrink: 0;
}
.PageTitle > * {
  margin: auto 0%;
}

.PTitle {
  position: relative;
  display: flex;

  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 32px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0.02em;
  text-align: left;

  color: white;
}
.PTButton {
  position: relative;
  display: flex;

  cursor: pointer;

  padding: 8px;

  width: fit-content;
  height: fit-content;

  background-color: #2c2c6c;
  border-radius: 4px;

  font-family: "Montserrat";
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  text-align: left;
  color: #ffffffe5;

  transition: 0.2s;
}
.PTButton:hover {
  background-color: #656bff4d;
}

.ChatWrapper {
  position: relative;
  display: flex;

  padding-right: 5px;

  flex-direction: column;
  justify-content: space-between;
  gap: 0;

  width: 100%;

  min-height: 0;
  height: 100%;

  background-color: #1e1b4a;
  border-radius: var(--o-s-global-border-radius);

  overflow: hidden;

  flex-shrink: 1;
}
.Chat {
  position: relative;
  display: flex;

  flex-direction: column;
  justify-content: flex-start;
  gap: 36px;

  padding: 20px 20px 36px 20px;

  width: 100%;
  height: auto;

  overflow: auto;

  flex-shrink: 1;
}
.ChatInput {
  position: relative;
  display: flex;

  padding: 8px 20px 20px 20px;

  width: 100%;
  height: fit-content;

  flex-shrink: 0;
}

.InputWrapper {
  position: relative;
  display: flex;

  flex-direction: row;
  justify-content: space-between;
  gap: 4px;

  padding: 12px 8px;

  width: 100%;
  height: fit-content;

  border: 1px solid #27235c;
  border-radius: 4px;
}
.InputWrapper > input {
  position: relative;
  display: flex;

  transition: 0.2s;

  width: 100%;
  height: auto;

  flex-shrink: 1;

  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.01em;
  text-align: left;

  color: #ffffff;

  border: none;

  background-color: transparent;
  outline: none;
}
.InputWrapper > .inpButton {
  position: relative;
  display: flex;

  transition: 0.2s;

  padding: 8px;

  width: fit-content;
  height: fit-content;

  flex-shrink: 0;

  border-radius: 4px;

  background-color: #656bff;

  font-family: "Montserrat";
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  text-align: left;
  color: #ffffffe5;
}
.InputWrapper > .inpButton:hover {
  background-color: #656bffe5;
}

.chatTip {
  position: absolute;
  display: block;

  bottom: -8px;
  left: 50%;

  font-family: "Montserrat";
  font-size: 10px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-align: center;
  text-transform: uppercase;

  color: #d3caff4d;

  transform: translateX(-50%) translateY(100%);
}

.ChatBubble {
  position: relative;
  display: flex;

  flex-direction: column;
  justify-content: flex-start;
  gap: 20px;

  padding: 20px;

  width: fit-content;
  max-width: 50%;

  height: fit-content;

  border-radius: var(--o-s-global-border-radius) var(--o-s-global-border-radius)
    var(--o-s-global-border-radius) 0;
  background-color: #27235c;
}
.ChatBubble.userBorder {
  margin-left: auto;
  border-radius: var(--o-s-global-border-radius) var(--o-s-global-border-radius)
    0 var(--o-s-global-border-radius);
}
.CBText {
  position: relative;
  display: flex;

  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.01em;
  text-align: left;

  color: #ffffff;
}

.CBTimeTip {
  position: absolute;
  display: block;

  bottom: -4px;
  right: 0%;

  width: max-content;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.01em;
  text-align: right;

  color: #d3caff4d;

  transform: translateY(100%);
}
.CBButtons {
  position: relative;
  display: flex;

  flex-direction: row;
  justify-content: flex-start;
  gap: 8px;
  flex-wrap: wrap;

  min-width: 0px;
  width: fit-content;
  max-width: 100%;
}

.SecondaryButton {
  position: relative;
  display: flex;

  padding: 12px 16px;

  width: fit-content;
  height: fit-content;

  border-radius: 4px;
  background-color: #363585;

  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;

  color: #ffffffe5;
}
</style>

<style scoped>
@media (max-width: 480px) {
  .PageContent {
    width: calc(100% - 20px);
  }
}

@media (max-width: 768px) {
  .PageTitle {
    flex-direction: column;
    justify-content: flex-start;
  }

  .ChatBubble {
    max-width: 90%;
  }

  .PTitle {
    font-size: 26px;
  }
}

@media (min-width: 480px) and (max-width: 1200px) {
  .PageContent {
    width: calc(100% - 40px);
  }
}
</style>

<style scoped>
.dot-container {
  position: relative;
  display: flex;

  flex-direction: row;
  justify-content: flex-start;
  gap: 2px;

  width: fit-content;
  height: fit-content;
}

.dot {
  position: relative;
  width: 5px;
  height: 5px;
  background-color: white;
  border-radius: 50%;
  animation: blink 1.5s infinite;
}

.dot:nth-child(1) {
  animation-delay: 0s;
}

.dot:nth-child(2) {
  animation-delay: 0.5s;
}

.dot:nth-child(3) {
  animation-delay: 1s;
}

@keyframes blink {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
</style>
