import request from "@/api/config.js";

export function sendMessage(data) {
  return request({
    timeout: 300000,
    url: `career_guidance/chat/message`,
    method: "post",
    data,
  });
}
